<template>
  <div class="container">
    <div class="hiddenInput">
      <input type="text" style="opacity: 0;">
      <input type="password" style="opacity: 0;">
    </div>
    <div class="loginPanel">
      <ElForm ref="loginForm" :model="loginForm" :rules="rules">
        <div class="loginMian">
          <h3 class="title">用户登录</h3>
          <FormItem prop="account">
            <ElInput ref="accountInput" v-model="loginForm.account" placeholder="请输入账号" onfocus="this.removeAttribute('readonly')" readonly class="text">
              <template slot="prepend"><i class="imow-icon-account" /></template>
            </ElInput>
          </FormItem>
          <FormItem prop="password">
            <ElInput v-model="loginForm.password" placeholder="请输入密码" onfocus="this.removeAttribute('readonly')" readonly class="text" type="password">
              <template slot="prepend"><i class="imow-icon-mima" /></template>
            </ElInput>
          </FormItem>

          <SlideCaptcha v-if="showCaptcha" @close="showCaptcha = false" @success="handleCaptchaSuccess" />

          <div class="linkDiv">
            <nuxt-link to="/password/find">忘记密码</nuxt-link>
            <!-- <nuxt-link to="/about">修改密码</nuxt-link> -->
          </div>
          <div class="buttonDiv">
            <ElButton type="primary" @click="submitForm('loginForm')">登录>></ElButton>
          </div>
          <FormItem prop="policy">
            <ElCheckbox v-model="loginForm.policy">已仔细阅读并同意<span style="cursor: pointer;" @click="handleNavToPolicy($event)">&lt;&lt;隐私政策&gt;&gt;</span></ElCheckbox>
          </FormItem>
          <!-- <div class="bDiv">
              <nuxt-link to="/about">申请加入>></nuxt-link>
            </div> -->
        </div>
      </ElForm>
    </div>
  </div>
</template>

  <script>
import { Button, Input, Form, FormItem, checkbox, Message } from "element-ui";
import { login } from "@/api/login";
import SlideCaptcha from "../components/SlideCaptcha.vue";
export default {
  ssr: false,
  layout: "loginAndRegister",
  components: {
    ElButton: Button,
    ElInput: Input,
    ElForm: Form,
    FormItem,
    ElCheckbox: checkbox,
    SlideCaptcha
  },

  data() {
    const policyCheck = (rule, value, callback) => {
      if (value === true) {
        callback();
      } else {
        callback(new Error("请阅读并同意隐私政策"));
      }
    };
    return {
      loginForm: {
        account: undefined,
        password: undefined,
        policy: false
      },
      rules: {
        account: [{ required: true, message: "请输入账号", trigger: "change" }],
        password: [
          { required: true, message: "请输入密码", trigger: "change" }
        ],
        policy: [
          {
            required: true,
            message: "请阅读并同意隐私政策",
            trigger: "blue"
          },
          {
            validator: policyCheck,
            message: "请阅读并同意隐私政策",
            trigger: "change"
          }
        ]
      },
      showCaptcha: false,
      CaptchaId: undefined
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.accountInput.focus();
    });
    window.addEventListener("keyup", this.keyDown);
  },
  destroyed() {
    window.removeEventListener("keyup", this.keyDown, false);
  },
  methods: {
    handleCaptchaSuccess(data) {
        console.log(data) // eslint-disable-line
      this.login(data.requestId);
    },
    async submitForm(formName) {
      this.$refs[formName].validate(async valid => {
        if (valid) {
          this.showCaptcha = true;
        } else {
          return false;
        }
      });
    },
    async login(captchaId) {
      const data = {
        account: this.loginForm.account,
        password: this.loginForm.password,
        captchaId
      };
      let res = await login(data);
      if (res.status == 0) {
        // 登录成功
        this.$store.commit("login", res.userInfo);
        sessionStorage.setItem("token", res.userInfo.token);
        this.$router.push({ path: "/" });
      } else {
        this.showCaptcha = false;
        Message({
          type: "error",
          message: res.message
        });
      }
    },
    // 监听enter键 执行事件
    keyDown(e) {
      if (e.keyCode === 13) {
        this.submitForm("loginForm");
      }
    },
    handleNavToPolicy(e) {
      e.preventDefault();
      window.open("/privacyPolicy", "_blank");
    }
  }
};
</script>

<style scoped>
.hiddenInput {
  position: absolute;
  z-index: 1;
  margin-top: 160px;
  left: 60%;
}

.container {
  width: 100%;
  background-image: url("~assets/images/loginBackground.jpg");
}
.loginPanel {
  width: 445px;
  height: 400px;
  background-color: #ffffff;
  position: relative;
  z-index: 10;
  left: 60%;
  margin: 160px 0px 220px 0px;
}
.loginMian {
  padding: 40px 60px;
}
.title {
  padding-bottom: 45px;
  font-size: 18px;
  font-weight: bold;
  color: #6e6e6e;
}
.linkDiv {
  position: relative;
  right: 0px;
  font-size: 12px;
  text-align: right;
  margin-top: 13px;
}
.linkDiv a {
  color: #bfbfbf;
  margin-left: 20px;
}
.buttonDiv {
  margin-top: 25px;
}

.imow-icon-account,
.imow-icon-mima {
  font-size: 30px;
}
.bDiv {
  margin-top: 15px;
}
.bDiv a {
  color: #da2631;
  float: right;
}
</style>


<style>
/* change element-ui style */
.loginPanel /deep/ .el-input-group__prepend {
  background-color: #dcdcdc;
  color: #626262;
  padding: 0px 10px 0px 10px;
}
.loginPanel /deep/ .el-input__inner {
  height: 44px;
  width: 272px;
}
.buttonDiv .el-button--primary {
  font-size: 23px;
  height: 52px;
  width: 100%;
}
.bDiv .el-checkbox__label,
.bDiv a {
  font-size: 12px;
}
.bDiv .el-checkbox__label {
  padding-left: 5px;
}
.el-checkbox__inner {
  border: 1px solid #a0a0a0;
}
</style>
