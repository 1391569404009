<template>

  <div class="captcha-wrapper">
    <lazy-slide-captcha
      ref="captcha"
      :width="width"
      :height="height"
      :show-refresh="true"
      :fail-tip="failTip"
      :success-tip="successTip"
      @finish="handleFinish"
      @refresh="generate"
    />
    <span class="close-btn" @click="close"><i class="el-icon-close" />关闭</span>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "HelloWorld",
  props: {
    msg: {
      type: String,
      default: "默认消息"
    }
  },
  data() {
    return {
      requestId: undefined,
      failTip: "失败的原因",
      successTip: "胜利的原因",
      // width，height保持与552 * 344同比例即可
      width: 340,
      height: 212
    };
  },
  mounted() {
    this.generate();
  },
  methods: {
    close() {
      this.$emit("close");
    },
    generate() {
      // 改变内部状态，标识生成请求开始
      this.$refs.captcha.startRequestGenerate();

      axios
        .get("https://gateway.imow.cn/openapi/captcha/gen")
        .then(response => {
          console.log(response.data) // eslint-disable-line
          this.requestId = response.data.id;
          // 改变内部状态，标识生成请求结束，同时设定background，slider图像
          this.$refs.captcha.endRequestGenerate(
            response.data.backgroundImage,
            response.data.sliderImage
          );
        })
        .catch(error => {
          console.log(error); // eslint-disable-line
          // 标识生成请求结束
          this.$refs.captcha.endRequestGenerate(null, null);
        });
    },
    handleFinish(data) {
      // 改变内部状态，标识验证请求开始
      this.$refs.captcha.startRequestVerify();

      axios
        .post(
          `https://gateway.imow.cn/openapi/captcha/check?id=${this.requestId}`,
          data
        )
        .then(response => {
          let success = response.data.result === 0;
          // 验证失败时显示信息
          this.failTip =
            response.data.result == 1
              ? "验证未通过，拖动滑块将悬浮图像正确合并"
              : "验证超时, 请重新操作";
          // 验证通过时显示信息
          this.successTip = "验证通过，超过80%用户";
          // 改变内部状态，标识验证请求结束，同时设定是否成功状态
          this.$refs.captcha.endRequestVerify(success);

          if (!success) {
            setTimeout(() => {
              this.generate();
            }, 1000);
          } else {
            this.$emit("success", {
              requestId: this.requestId
            });
          }
        })
        .catch(error => {
          console.log(error); // eslint-disable-line
          this.failTip = "服务异常，请稍后重试";
          // 标识验证请求结束
          this.$refs.captcha.endRequestVerify(false);
        });
    }
  }
};
</script>

<style>
@-webkit-keyframes slidetounlock-data-v-273e6401 {
  0% {
    background-position: var(--pwidth) 0;
  }

  to {
    background-position: var(--width) 0;
  }
}

@-webkit-keyframes slidetounlock2-data-v-273e6401 {
  0% {
    background-position: var(--pwidth) 0;
  }

  to {
    background-position: var(--pwidth) 0;
  }
}

@keyframes slidetounlock-data-v-273e6401 {
  0% {
    background-position: var(--pwidth) 0;
  }

  to {
    background-position: var(--width) 0;
  }
}

@keyframes slidetounlock2-data-v-273e6401 {
  0% {
    background-position: var(--pwidth) 0;
  }

  to {
    background-position: var(--pwidth) 0;
  }
}
</style>

<style scoped>
.captcha-wrapper {
  background-color: white;
  position: absolute;
  width: 340px;
  opacity: 1;
  z-index: 1000;
  height: 310px;
  border-radius: 10px;
  overflow: hidden;
  top: 64px;
  box-shadow: 0px 0px 3px #6e6e6e;
}

.captcha {
  position: absolute;
  top: 0px;
}
.captcha_slider {
  position: relative;
}
.close-btn {
  position: absolute;
  font-size: 14px;
  bottom: 20px;
  right: 10px;
  color: #505050;
  cursor: pointer;
  user-select: none;
}
</style>
